/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
var ContainerStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: 250px;\n  overflow-y: auto;\n  ", "\n"], ["\n  max-height: 250px;\n  overflow-y: auto;\n  ", "\n"])), function (_a) {
    var isScrolling = _a.isScrolling;
    return "\n    padding-right: ".concat(isScrolling ? 4 : 0, "px;\n  ");
});
export default ContainerStyled;
var templateObject_1;
