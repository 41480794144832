/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
import { colors } from '../../../../styles/theme';
var ArrowStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 5px;\n  height: 5px;\n  border-bottom-right-radius: 1px;\n  background-color: ", ";\n  transform: rotate(45deg);\n"], ["\n  width: 5px;\n  height: 5px;\n  border-bottom-right-radius: 1px;\n  background-color: ", ";\n  transform: rotate(45deg);\n"])), colors.grey150);
export default ArrowStyled;
var templateObject_1;
