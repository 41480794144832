var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Skeleton, Box, Typography, List, ListDivider, ListItem } from '@mui/joy';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
export default function AIAgentsList(props) {
    var _a;
    var flows = props.flows, selectedFlowId = props.selectedFlowId, loading = props.loading, onFlowSelect = props.onFlowSelect;
    var handleItemClick = function (flowId) {
        onFlowSelect(flowId);
    };
    return (_jsx(List, __assign({ sx: (_a = {},
            _a["& .".concat(listItemButtonClasses.root, ".").concat(listItemButtonClasses.selected)] = {
                borderLeft: '2px solid',
                borderLeftColor: 'var(--joy-palette-primary-outlinedBorder)',
            },
            _a) }, { children: loading ?
            _jsxs(_Fragment, { children: [_jsx(ListItem, { children: _jsx(Skeleton, { loading: loading, height: 48, sx: { position: "relative" } }) }), _jsx(ListItem, { children: _jsx(Skeleton, { loading: loading, height: 48, sx: { position: "relative" } }) }), _jsx(ListItem, { children: _jsx(Skeleton, { loading: loading, height: 48, sx: { position: "relative" } }) })] })
            :
                flows.map(function (flow, index) { return (_jsxs(React.Fragment, { children: [_jsx(ListItem, { children: _jsx(ListItemButton, __assign({}, (flow.id === selectedFlowId && {
                                selected: true,
                                color: 'neutral',
                            }), { sx: { p: 2 }, onClick: function () { return handleItemClick(flow.id); } }, { children: _jsx(Box, __assign({ sx: { width: '100%', maxWidth: '200px' } }, { children: _jsxs("div", { children: [_jsx(Typography, __assign({ level: "title-sm", noWrap: true }, { children: flow.name })), _jsx(Typography, __assign({ level: "body-sm" }, { children: flow.flowDescription }))] }) })) })) }), _jsx(ListDivider, { sx: { m: 0 } })] }, index)); }) })));
}
