import { FilterEnums } from '../types/enums';
export var dateOptions = [
    { name: 'By default', value: FilterEnums.clear },
    { name: 'Today', value: FilterEnums.today },
    { name: 'Yesterday', value: FilterEnums.yesterday },
    { name: 'This week', value: FilterEnums.thisWeek },
    { name: 'Last week', value: FilterEnums.lastWeek },
    { name: 'This month', value: FilterEnums.thisMoth },
    { name: 'Last month', value: FilterEnums.lastMonth },
    { name: 'Last 60 days', value: FilterEnums.last60Days },
    { name: 'Last 90 days', value: FilterEnums.last90Days },
];
export var resolutionOptions = [
    { name: 'By default', value: FilterEnums.clear },
    { name: 'Resolved', value: FilterEnums.resolved },
    { name: 'Ticket created', value: FilterEnums.ticketCreated },
];
export var feedbackOptions = [
    { name: 'By default', value: FilterEnums.clear },
    { name: 'Liked', value: FilterEnums.liked },
    { name: 'Disliked', value: FilterEnums.disliked },
];
