var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useRequest } from '../../hooks/useRequest';
import { conversationsUsersAtom, conversationsUsersIsLoadingAtom, sessionsAtom, sessionsIsLoadingAtom, conversationsAtom, conversationsIsLoadingAtom, workspaceConversationsStatsAtom, workspaceConversationsStatsIsLoadingAtom, workspaceVoiceConversationsStatsAtom, workspaceVoiceConversationsStatsIsLoadingAtom, conversationsUsersTotalCountAtom, conversationsAllUserEmailsAtom, } from '../store';
import { dateFilterValuesGenerator, generateQueryParams } from '../../utils';
import { FilterEnums } from '../../types/enums';
export var useConversations = function () {
    var request = useRequest();
    var syncUsers = useRef(null).current;
    var syncSessions = useRef(null).current;
    var _a = useRecoilState(conversationsUsersAtom), conversationsUsers = _a[0], setConversationsUsers = _a[1];
    var _b = useRecoilState(conversationsAllUserEmailsAtom), allUserEmails = _b[0], setAllUserEmails = _b[1];
    var _c = useRecoilState(conversationsUsersTotalCountAtom), conversationsUsersTotalCount = _c[0], setConversationsUsersTotalCount = _c[1];
    var _d = useRecoilState(conversationsUsersIsLoadingAtom), conversationsUsersIsLoading = _d[0], setConversationsUsersIsLoading = _d[1];
    var _e = useRecoilState(sessionsAtom), sessions = _e[0], setSessions = _e[1];
    var _f = useRecoilState(sessionsIsLoadingAtom), sessionsIsLoading = _f[0], setSessionsIsLoading = _f[1];
    var _g = useRecoilState(conversationsAtom), conversations = _g[0], setConversations = _g[1];
    var _h = useRecoilState(conversationsIsLoadingAtom), conversationsIsLoading = _h[0], setConversationsIsLoading = _h[1];
    var _j = useRecoilState(workspaceConversationsStatsAtom), workspaceConversationsStats = _j[0], setWorkspaceConversationsStats = _j[1];
    var _k = useRecoilState(workspaceConversationsStatsIsLoadingAtom), workspaceConversationsStatsIsLoading = _k[0], setWorkspaceConversationsStatsIsLoading = _k[1];
    var _l = useRecoilState(workspaceVoiceConversationsStatsAtom), workspaceVoiceConversationsStats = _l[0], setWorkspaceVoiceConversationsStats = _l[1];
    var _m = useRecoilState(workspaceVoiceConversationsStatsIsLoadingAtom), workspaceVoiceConversationsStatsIsLoading = _m[0], setWorkspaceVoiceConversationsStatsIsLoading = _m[1];
    var getConversationsUsers = function (page, filterData, onSuccess) {
        var _a;
        if (page !== 1 && conversationsUsersTotalCount === conversationsUsers.length)
            return;
        setConversationsUsersIsLoading(true);
        var query = generateQueryParams(__assign(__assign({}, dateFilterValuesGenerator(filterData.date)), (_a = { userIds: filterData.userIds ? [filterData.userIds] : [], pageSize: 20, pageNumber: page }, _a[filterData.feedback] = true, _a)));
        request.get("/api/ConversationAnalytics/workspaceUsers?".concat(query))
            .then(function (_a) {
            var data = _a.data;
            var conversationUserModels = data.conversationUserModels, totalCount = data.totalCount;
            var newList = page === 1 ? conversationUserModels : __spreadArray(__spreadArray([], conversationsUsers, true), conversationUserModels, true);
            syncUsers = newList;
            setConversationsUsers(newList);
            setConversationsUsersTotalCount(totalCount);
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        })
            .catch(console.log)
            .finally(function () { return setConversationsUsersIsLoading(false); });
    };
    var getAllUserEmails = function () {
        request.get("/api/ConversationAnalytics/workspaceUsers?".concat(generateQueryParams({ pageSize: 999999999 })))
            .then(function (_a) {
            var data = _a.data;
            setAllUserEmails(__spreadArray([
                { name: 'By default', value: FilterEnums.clear }
            ], data.conversationUserModels
                .filter(function (user) { return !!user.email; })
                .map(function (user) { return ({ name: user.email, value: user.userId }); }), true));
        })
            .catch(console.log);
    };
    var getSessions = function (userId, filterData, onSuccess) {
        var _a;
        if (sessions[userId])
            return;
        setSessionsIsLoading(true);
        var query = generateQueryParams(__assign(__assign({}, dateFilterValuesGenerator(filterData.date)), (_a = {}, _a[filterData.feedback] = true, _a)));
        request.get("/api/ConversationAnalytics/sessionsByUserId/".concat(userId, "?").concat(query))
            .then(function (_a) {
            var _b, _c;
            var data = _a.data;
            syncSessions = __assign(__assign({}, sessions), (_b = {}, _b[userId] = data, _b));
            setSessions(__assign(__assign({}, sessions), (_c = {}, _c[userId] = data, _c)));
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        })
            .catch(console.log)
            .finally(function () { return setSessionsIsLoading(false); });
    };
    var getConversations = function (sessionId) {
        if (conversations[sessionId])
            return;
        setConversationsIsLoading(true);
        request.get("/api/ConversationAnalytics/conversationsBySessionId/".concat(sessionId))
            .then(function (_a) {
            var _b;
            var data = _a.data;
            return setConversations(__assign(__assign({}, conversations), (_b = {}, _b[sessionId] = data, _b)));
        })
            .catch(console.log)
            .finally(function () { return setConversationsIsLoading(false); });
    };
    var getWorkspaceConversationsStats = function () {
        setWorkspaceConversationsStatsIsLoading(true);
        request.get('/api/ConversationAnalytics/workspaceConversationsStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceConversationsStats(data);
        })
            .catch(console.log)
            .finally(function () { return setWorkspaceConversationsStatsIsLoading(false); });
    };
    var getWorkspaceVoiceConversationsStats = function () {
        setWorkspaceVoiceConversationsStatsIsLoading(true);
        request.get('/api/ConversationAnalytics/workspaceVoiceConversationsStats')
            .then(function (_a) {
            var data = _a.data;
            return setWorkspaceVoiceConversationsStats(data);
        })
            .catch(console.log)
            .finally(function () { return setWorkspaceVoiceConversationsStatsIsLoading(false); });
    };
    var markSessionAsRead = function (userId, sessionId) {
        var _a;
        var _b, _c;
        var currentSessions = Object.keys(sessions).length !== 0 ? sessions : syncSessions;
        var currentUsers = conversationsUsers.length !== 0 ? conversationsUsers : syncUsers;
        if (!(currentSessions === null || currentSessions === void 0 ? void 0 : currentSessions[userId]) || !currentUsers)
            return;
        var hasUnreadSessionInUser = (_b = currentSessions[userId]) === null || _b === void 0 ? void 0 : _b.some(function (session) { return session.hasUnreadMessages && session.sessionId !== sessionId; });
        var newSessions = __assign(__assign({}, currentSessions), (_a = {}, _a[userId] = (_c = (currentSessions[userId])) === null || _c === void 0 ? void 0 : _c.map(function (session) { return session.sessionId === sessionId ? __assign(__assign({}, session), { hasUnreadMessages: false }) : session; }), _a));
        var newUsers = currentUsers === null || currentUsers === void 0 ? void 0 : currentUsers.map(function (user) { return user.userId === userId ? __assign(__assign({}, user), { hasUnreadMessages: hasUnreadSessionInUser }) : user; });
        syncSessions = newSessions;
        syncUsers = newUsers;
        setSessions(newSessions);
        setConversationsUsers(newUsers);
        request.put("/api/ConversationAnalytics/markSessionAsRead/".concat(sessionId))
            .then(function (_a) {
            var data = _a.data;
            return console.log(data);
        })
            .catch(console.log);
    };
    var humanReply = function (data) {
        var _a;
        var sessionId = data.sessionId, replyMessage = data.replyMessage, conversationItemId = data.conversationItemId, userMessage = data.userMessage;
        var replayedItem = {
            userFeedback: 0,
            isAnswered: true,
            isHumanReply: true,
            isCrawlingFinished: true,
            botResponse: replyMessage,
            humanRepliedUserMessage: userMessage,
            createdDate: new Date().toISOString(),
            humanRepliedConversationItemId: conversationItemId,
        };
        var requestData = {
            replyToConversationItemId: conversationItemId,
            content: replyMessage,
        };
        setConversations(__assign(__assign({}, conversations), (_a = {}, _a[sessionId] = __spreadArray(__spreadArray([], conversations[sessionId], true), [replayedItem], false), _a)));
        request.post('/Chat/send/humanReply', requestData)
            .then(function () { return null; })
            .catch(console.log);
    };
    return {
        conversationsUsers: conversationsUsers,
        conversationsUsersIsLoading: conversationsUsersIsLoading,
        sessions: sessions,
        conversations: conversations,
        sessionsIsLoading: sessionsIsLoading,
        conversationsIsLoading: conversationsIsLoading,
        workspaceConversationsStats: workspaceConversationsStats,
        workspaceConversationsStatsIsLoading: workspaceConversationsStatsIsLoading,
        workspaceVoiceConversationsStats: workspaceVoiceConversationsStats,
        workspaceVoiceConversationsStatsIsLoading: workspaceVoiceConversationsStatsIsLoading,
        allUserEmails: allUserEmails,
        getConversationsUsers: getConversationsUsers,
        getSessions: getSessions,
        getConversations: getConversations,
        getWorkspaceConversationsStats: getWorkspaceConversationsStats,
        getWorkspaceVoiceConversationsStats: getWorkspaceVoiceConversationsStats,
        markSessionAsRead: markSessionAsRead,
        humanReply: humanReply,
        getAllUserEmails: getAllUserEmails,
    };
};
