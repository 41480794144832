var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLayer, useHover } from 'react-laag';
// components
import View from '../../styledComponents/View';
import Text from '../../styledComponents/Text';
import ContentStyled from './styled/ContentStyled';
import ArrowStyled from './styled/ArrowStyled';
import ArrowWrapperStyled from './styled/ArrowWrapperStyled';
// constants
import { HIGH_Z_INDEXES } from '../../../constants';
var Tooltip = function (props) {
    var width = props.width, message = props.message, children = props.children, placement = props.placement, isBlock = props.isBlock, isShow = props.isShow, disabled = props.disabled, renderContent = props.renderContent, restProps = __rest(props, ["width", "message", "children", "placement", "isBlock", "isShow", "disabled", "renderContent"]);
    var _a = useHover({
        delayEnter: 500,
        delayLeave: 100,
        hideOnScroll: true
    }), isOver = _a[0], hoverProps = _a[1], close = _a[2];
    var _b = useLayer({
        isOpen: ((isOver && isShow === null) || isShow) && !disabled,
        triggerOffset: 8,
        placement: placement
    }), triggerProps = _b.triggerProps, layerProps = _b.layerProps, renderLayer = _b.renderLayer, layerSide = _b.layerSide, arrowProps = _b.arrowProps;
    return (_jsxs(_Fragment, { children: [_jsx(View, __assign({ onClick: close }, hoverProps, triggerProps, { 
                // @ts-ignore
                display: isBlock ? 'inline-block' : '' }, restProps, { children: children })), ((isOver && isShow === null) || isShow) &&
                !disabled &&
                renderLayer(_jsxs(ContentStyled, __assign({ maxW: width }, layerProps, { 
                    // @ts-ignore
                    layerSide: layerSide, zIndex: HIGH_Z_INDEXES.LEVEL_2 }, { children: [renderContent ? (renderContent()) : (
                        // @ts-ignore
                        _jsx(Text, __assign({ typo: "14/white" }, { children: message }))), _jsx(ArrowWrapperStyled, __assign({}, arrowProps, { children: _jsx(ArrowStyled, {}) }))] })))] }));
};
Tooltip.defaultProps = {
    width: '100%',
    isShow: null,
    message: '',
    isBlock: false,
    disabled: false,
    placement: 'top-start'
};
export default Tooltip;
