import eyeHide from '../../../assets/icons/eyeHide.svg';
import eyeShow from '../../../assets/icons/eyeShow.svg';
import google from '../../../assets/icons/google.svg';
import loading from '../../../assets/icons/loading.svg';
import arrowLeft from '../../../assets/icons/arrowLeft.svg';
import close from '../../../assets/icons/close.svg';
import collapse from '../../../assets/icons/collapse.svg';
import uncollapse from '../../../assets/icons/uncollapse.svg';
import check from '../../../assets/icons/check.svg';
import jira from '../../../assets/icons/jira.svg';
import message from '../../../assets/icons/message.svg';
import slack from '../../../assets/icons/slack.svg';
import telegram from '../../../assets/icons/telegram.svg';
import monitor from '../../../assets/icons/monitor.svg';
import edit from '../../../assets/icons/edit.svg';
import addProject from '../../../assets/icons/addProject.svg';
import notification from '../../../assets/icons/notification.svg';
import phases from '../../../assets/icons/phases.svg';
import staffing from '../../../assets/icons/staffing.svg';
import resources from '../../../assets/icons/resources.svg';
import analytics from '../../../assets/icons/analytics.svg';
import settings from '../../../assets/icons/settings.svg';
import download from '../../../assets/icons/download.svg';
import add from '../../../assets/icons/add.svg';
import projects from '../../../assets/icons/projects.svg';
import alertInfo from '../../../assets/icons/alertInfo.svg';
import alertSuccess from '../../../assets/icons/alertSuccess.svg';
import alertWarning from '../../../assets/icons/alertWarning.svg';
import alertDanger from '../../../assets/icons/alertDanger.svg';
import error from '../../../assets/icons/error.svg';
import dowork from '../../../assets/icons/dowork.svg';
import doworkLogo from '../../../assets/icons/doworkLogo.svg';
import home from '../../../assets/icons/home.svg';
import addTask from '../../../assets/icons/addTask.svg';
import expand from '../../../assets/icons/expand.svg';
import deleteBin from '../../../assets/icons/delete.svg';
import deleteOutline from '../../../assets/icons/deleteOutline.svg';
import warning from '../../../assets/icons/warning.svg';
import warningIcon from '../../../assets/icons/warningIcon.svg';
import move from '../../../assets/icons/move.svg';
import zoomIn from '../../../assets/icons/zoomIn.svg';
import zoomOut from '../../../assets/icons/zoomOut.svg';
import timeline from '../../../assets/icons/timeline.svg';
import start from '../../../assets/icons/start.svg';
import end from '../../../assets/icons/end.svg';
import reorder from '../../../assets/icons/reorder.svg';
import groupOpen from '../../../assets/icons/groupOpen.svg';
import groupClose from '../../../assets/icons/groupClose.svg';
import subGroupOpen from '../../../assets/icons/subGroupOpen.svg';
import subGroupClose from '../../../assets/icons/subGroupClose.svg';
import sortingUp from '../../../assets/icons/sortingUp.svg';
import sortingDown from '../../../assets/icons/sortingDown.svg';
import success from '../../../assets/icons/success.svg';
import drag from '../../../assets/icons/drag.svg';
import addGroup from '../../../assets/icons/addGroup.svg';
import unassigned from '../../../assets/icons/unassigned.svg';
import average from '../../../assets/icons/average.svg';
import emptyPhase from '../../../assets/icons/emptyPhase.svg';
import reduce from '../../../assets/icons/reduce.svg';
import left from '../../../assets/icons/left.svg';
import right from '../../../assets/icons/right.svg';
import toRight from '../../../assets/icons/toRight.svg';
import toLeft from '../../../assets/icons/toLeft.svg';
import user from '../../../assets/icons/user.svg';
import lock from '../../../assets/icons/lock.svg';
import buffer from '../../../assets/icons/buffer.svg';
import uncertainty from '../../../assets/icons/uncertainty.svg';
import defaultType from '../../../assets/icons/defaultType.svg';
import infoSummary from '../../../assets/icons/infoSummary.svg';
import archive from '../../../assets/icons/archive.svg';
import unarchive from '../../../assets/icons/unarchive.svg';
import leave from '../../../assets/icons/leave.svg';
import shareToEmail from '../../../assets/icons/shareToEmail.svg';
import shareToPublic from '../../../assets/icons/shareToPublic.svg';
import share from '../../../assets/icons/share.svg';
import logout from '../../../assets/icons/logout.svg';
import contactUs from '../../../assets/icons/contactUs.svg';
import arrowRight from '../../../assets/icons/arrowRight.svg';
import pin from '../../../assets/icons/pin.svg';
import upgradeStar from '../../../assets/icons/upgradeStar.svg';
import mostPopular from '../../../assets/icons/mostPopular.svg';
import expandDetails from '../../../assets/icons/expandDetails.svg';
import notAvailable from '../../../assets/icons/notAvailable.svg';
import phaseDrag from '../../../assets/icons/phaseDrag.svg';
import expandBoard from '../../../assets/icons/expandBoard.svg';
import collapseBoard from '../../../assets/icons/collapseBoard.svg';
import board from '../../../assets/icons/board.svg';
import csvImport from '../../../assets/icons/csvImport.svg';
import newProject from '../../../assets/icons/newProject.svg';
import addCircle from '../../../assets/icons/addCircle.svg';
import dragCloud from '../../../assets/icons/dragCloud.svg';
import expandGroups from '../../../assets/icons/expandGroups.svg';
import collapseGroups from '../../../assets/icons/collapseGroups.svg';
import aiBot from '../../../assets/icons/aiBot.svg';
import task from '../../../assets/icons/task.svg';
import sort from '../../../assets/icons/sort.svg';
import search from '../../../assets/icons/search.svg';
import empty from '../../../assets/icons/empty.svg';
import replace from '../../../assets/icons/replace.svg';
import properties from '../../../assets/icons/properties.svg';
import remap from '../../../assets/icons/remap.svg';
import integrations from '../../../assets/icons/integrations.svg';
import dateAndTime from '../../../assets/icons/dateAndTime.svg';
import changeUserPic from '../../../assets/icons/changeUserPic.svg';
import defaultUser from '../../../assets/icons/defaultUser.svg';
import filters from '../../../assets/icons/filters.svg';
import webhook from '../../../assets/icons/webhook.svg';
import customize from '../../../assets/icons/customize.svg';
import searchList from '../../../assets/icons/searchList.svg';
import exportIcon from '../../../assets/icons/exportIcon.svg';
import options from '../../../assets/icons/options.svg';
import template from '../../../assets/icons/template.svg';
import templatePreview from '../../../assets/icons/templatePreview.svg';
import templateDoc from '../../../assets/icons/templateDoc.svg';
import addProjectDoc from '../../../assets/icons/addProjectDoc.svg';
import importDoc from '../../../assets/icons/importDoc.svg';
import actions from '../../../assets/icons/actions.svg';
import file from '../../../assets/icons/file.svg';
import calendar from '../../../assets/icons/calendar.svg';
import help from '../../../assets/icons/help.svg';
import contact from '../../../assets/icons/contact.svg';
import errorSecondary from '../../../assets/icons/errorSecondary.svg';
import warningStroke from '../../../assets/icons/warningStroke.svg';
import arrowBack from '../../../assets/icons/arrowBack.svg';
import checkboxCheck from '../../../assets/icons/checkboxCheck.svg';
import chevronDown from '../../../assets/icons/chevronDown.svg';
import emptyLinks from '../../../assets/icons/emptyLinks.svg';
import priorityCritical from '../../../assets/icons/priorities/priorityCritical.svg';
import priorityVeryHigh from '../../../assets/icons/priorities/priorityVeryHigh.svg';
import priorityHigh from '../../../assets/icons/priorities/priorityHigh.svg';
import priorityMedium from '../../../assets/icons/priorities/priorityMedium.svg';
import priorityLow from '../../../assets/icons/priorities/priorityLow.svg';
import priorityVeryLow from '../../../assets/icons/priorities/priorityVeryLow.svg';
import toBottom from '../../../assets/icons/toBottom.svg';
import minus from '../../../assets/icons/minus.svg';
import swap from '../../../assets/icons/swap.svg';
import back from '../../../assets/icons/back.svg';
import workspaces from '../../../assets/icons/workspaces.svg';
import copy from '../../../assets/icons/copy.svg';
import summary from '../../../assets/icons/summary.svg';
import infoSecondary from '../../../assets/icons/infoSecondary.svg';
import setup from '../../../assets/icons2/setup.svg';
import analyzedContent from '../../../assets/icons2/analyzedContent.svg';
import conversations from '../../../assets/icons2/conversations.svg';
import ticketManagement from '../../../assets/icons2/ticketManagement.svg';
import intercom from '../../../assets/icons2/intercom.svg';
import commonQueries from '../../../assets/icons2/commonQueries.svg';
import like from '../../../assets/icons2/like.svg';
import dislike from '../../../assets/icons2/dislike.svg';
import noConnection from '../../../assets/icons2/noConnection.svg';
import reply from '../../../assets/icons2/reply.svg';
import send from '../../../assets/icons2/send.svg';
import questionMark from '../../../assets/icons2/questionMark.svg';
import conversationFlows from '../../../assets/icons2/conversationFlows.svg';
import sources from '../../../assets/icons2/sources.svg';
import hubspot from '../../../assets/icons2/hubspot.svg';
import emptyState from '../../../assets/icons2/emptyState.svg';
import twilio from '../../../assets/icons/twilio.svg';
var icons = {
    setup: setup,
    analyzedContent: analyzedContent,
    conversations: conversations,
    ticketManagement: ticketManagement,
    intercom: intercom,
    commonQueries: commonQueries,
    like: like,
    dislike: dislike,
    noConnection: noConnection,
    reply: reply,
    send: send,
    questionMark: questionMark,
    conversationFlows: conversationFlows,
    sources: sources,
    hubspot: hubspot,
    emptyState: emptyState,
    eyeHide: eyeHide,
    eyeShow: eyeShow,
    google: google,
    loading: loading,
    arrowLeft: arrowLeft,
    close: close,
    collapse: collapse,
    uncollapse: uncollapse,
    check: check,
    jira: jira,
    message: message,
    slack: slack,
    telegram: telegram,
    monitor: monitor,
    edit: edit,
    addProject: addProject,
    notification: notification,
    phases: phases,
    staffing: staffing,
    resources: resources,
    analytics: analytics,
    settings: settings,
    download: download,
    add: add,
    projects: projects,
    alertInfo: alertInfo,
    alertSuccess: alertSuccess,
    alertWarning: alertWarning,
    alertDanger: alertDanger,
    error: error,
    dowork: dowork,
    doworkLogo: doworkLogo,
    home: home,
    addTask: addTask,
    expand: expand,
    deleteBin: deleteBin,
    deleteOutline: deleteOutline,
    warning: warning,
    warningIcon: warningIcon,
    move: move,
    zoomIn: zoomIn,
    zoomOut: zoomOut,
    timeline: timeline,
    start: start,
    end: end,
    reorder: reorder,
    groupOpen: groupOpen,
    groupClose: groupClose,
    subGroupOpen: subGroupOpen,
    subGroupClose: subGroupClose,
    sortingUp: sortingUp,
    sortingDown: sortingDown,
    success: success,
    drag: drag,
    addGroup: addGroup,
    unassigned: unassigned,
    average: average,
    emptyPhase: emptyPhase,
    reduce: reduce,
    left: left,
    right: right,
    toRight: toRight,
    toLeft: toLeft,
    user: user,
    lock: lock,
    buffer: buffer,
    uncertainty: uncertainty,
    defaultType: defaultType,
    infoSummary: infoSummary,
    archive: archive,
    unarchive: unarchive,
    leave: leave,
    shareToEmail: shareToEmail,
    shareToPublic: shareToPublic,
    share: share,
    logout: logout,
    contactUs: contactUs,
    arrowRight: arrowRight,
    pin: pin,
    upgradeStar: upgradeStar,
    mostPopular: mostPopular,
    expandDetails: expandDetails,
    notAvailable: notAvailable,
    phaseDrag: phaseDrag,
    expandBoard: expandBoard,
    collapseBoard: collapseBoard,
    expandGroups: expandGroups,
    collapseGroups: collapseGroups,
    board: board,
    csvImport: csvImport,
    newProject: newProject,
    addCircle: addCircle,
    dragCloud: dragCloud,
    aiBot: aiBot,
    task: task,
    sort: sort,
    search: search,
    empty: empty,
    replace: replace,
    properties: properties,
    remap: remap,
    integrations: integrations,
    dateAndTime: dateAndTime,
    changeUserPic: changeUserPic,
    defaultUser: defaultUser,
    filters: filters,
    webhook: webhook,
    customize: customize,
    searchList: searchList,
    exportIcon: exportIcon,
    options: options,
    template: template,
    templatePreview: templatePreview,
    templateDoc: templateDoc,
    addProjectDoc: addProjectDoc,
    importDoc: importDoc,
    actions: actions,
    file: file,
    calendar: calendar,
    help: help,
    contact: contact,
    errorSecondary: errorSecondary,
    warningStroke: warningStroke,
    arrowBack: arrowBack,
    checkboxCheck: checkboxCheck,
    chevronDown: chevronDown,
    emptyLinks: emptyLinks,
    priorityCritical: priorityCritical,
    priorityVeryHigh: priorityVeryHigh,
    priorityHigh: priorityHigh,
    priorityMedium: priorityMedium,
    priorityLow: priorityLow,
    priorityVeryLow: priorityVeryLow,
    toBottom: toBottom,
    minus: minus,
    swap: swap,
    back: back,
    workspaces: workspaces,
    copy: copy,
    summary: summary,
    infoSecondary: infoSecondary,
    twilio: twilio
};
export default icons;
