/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
import { colors } from '../../../../styles/theme';
var ContentStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 8px;\n  border-radius: 4px;\n  background-color: ", ";\n"], ["\n  margin-top: 8px;\n  border-radius: 4px;\n  background-color: ", ";\n"])), colors.white);
export default ContentStyled;
var templateObject_1;
