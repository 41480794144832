/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
var arrowPosition = {
    top: 'translateY(-3.5px) translateX(-50%)',
    bottom: 'translateY(3.5px) translateX(-50%)',
    left: 'translateX(-3.5px) translateY(-50%)',
    right: 'translateX(3.5px) translateY(-50%)'
};
var ArrowStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  "
    // @ts-ignore
    , "\n"])), 
// @ts-ignore
function (_a) {
    var layerSide = _a.layerSide;
    return "\n      transform: ".concat(arrowPosition[layerSide], ";\n    ");
});
export default ArrowStyled;
var templateObject_1;
