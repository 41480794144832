/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
import { colors } from '../../../../styles/theme';
var MenuItemStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  border-left: 4px solid ", ";\n\n  & svg {\n    fill: ", ";\n  }\n\n  ", "\n\n\n"], ["\n  cursor: pointer;\n  border-left: 4px solid ", ";\n\n  & svg {\n    fill: ", ";\n  }\n\n  ", "\n\n\n"])), colors.none, colors.grey90, function (_a) {
    var isSub = _a.isSub, active = _a.active;
    return "\n    ".concat(!active
        ? "\n          &:hover {\n            background-color: ".concat(isSub ? colors.grey30 : colors.grey20, ";\n          }\n        ")
        : "\n          background-color: ".concat(isSub ? colors.grey30 : colors.grey20, ";\n          border-color: ").concat(isSub ? colors.none : colors.theme, ";\n\n          & svg {\n            fill: ").concat(colors.themeDark, ";\n          }\n        "), "\n  ");
});
export default MenuItemStyled;
var templateObject_1;
