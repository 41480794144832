var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Typography, Stack, Divider, Radio, FormControl, FormLabel, Grid, Select, Option, RadioGroup, Checkbox } from '@mui/joy';
import WebhookArgs from './components/WebhookArgs';
function Action(props) {
    var action = props.action, pluginIndex = props.pluginIndex, onPluginActionTypeChange = props.onPluginActionTypeChange, onPluginExecuteAfterChange = props.onPluginExecuteAfterChange, onPluginInjectResultChange = props.onPluginInjectResultChange, onPluginActionArgsPropertyChange = props.onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader = props.onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader = props.onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader = props.onPluginActionArgsDeleteHeader;
    return (_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Typography, __assign({ level: "title-sm" }, { children: "Action" })), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Specify the HTTP endpoint where you want to submit the collected data." })), _jsx(Divider, {}), _jsxs(Grid, __assign({ container: true, columnSpacing: 2, rowSpacing: 1, alignItems: "center" }, { children: [_jsx(Grid, __assign({ xs: 4 }, { children: _jsx(FormLabel, { children: "Action Type" }) })), _jsx(Grid, __assign({ xs: 8 }, { children: _jsx(FormLabel, { children: "When to Execute" }) })), _jsx(Grid, __assign({ xs: 4 }, { children: _jsx(FormControl, { children: _jsx(Select, __assign({ size: "sm", value: action.type, onChange: function (event, newValue) { return onPluginActionTypeChange(pluginIndex, newValue); } }, { children: _jsx(Option, __assign({ value: "webhook" }, { children: "HTTP Request" })) })) }) })), _jsx(Grid, __assign({ xs: 4 }, { children: _jsx(FormControl, __assign({ sx: { flexDirection: "row" } }, { children: _jsxs(RadioGroup, __assign({ orientation: "horizontal" }, { children: [_jsx(Radio, { size: "sm", checked: !action.executeAfterEachVariableSet, label: "At the end", onChange: function () { return onPluginExecuteAfterChange(pluginIndex, false); }, name: "radio-buttons" }), _jsx(Radio, { size: "sm", checked: action.executeAfterEachVariableSet, label: "After each variable set", onChange: function () { return onPluginExecuteAfterChange(pluginIndex, true); }, name: "radio-buttons" })] })) })) })), _jsx(Grid, __assign({ xs: 4 }, { children: _jsx(FormControl, __assign({ sx: { flexDirection: "row" } }, { children: _jsx(Checkbox, { size: "sm", label: "Use the result in the next step", checked: action.injectResult, onChange: function (event) { return onPluginInjectResultChange(pluginIndex, event.target.checked); } }) })) }))] })), action.type === "webhook" && action.argsObject &&
                _jsx(WebhookArgs, { args: action.argsObject, pluginIndex: pluginIndex, onPluginActionArgsPropertyChange: onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader: onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader: onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader: onPluginActionArgsDeleteHeader })] })));
}
export default memo(Action);
