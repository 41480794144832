var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import React from 'react';
import { useLayer } from 'react-laag';
// components
import Icon from '../Icon';
import View from '../../styledComponents/View';
import Text from '../../styledComponents/Text';
import NowrapText from '../../styledComponents/NowrapText';
import Item from './components/Item';
import ContainerStyled from './styled/ContainerStyled';
import FilterStyled from './styled/FilterStyled';
import OpeningBlock from './styled/OpeningBlockStyled';
import ContentStyled from './styled/ContentStyled';
// constants
import { HIGH_Z_INDEXES } from '../../../constants';
// hooks
import useIsScrolling from '../../../hooks/useIsScrolling';
var Dropdown = function (props) {
    var _a = props.data, data = _a === void 0 ? [] : _a, name = props.name, _b = props.label, label = _b === void 0 ? '' : _b, error = props.error, width = props.width, value = props.value, filter = props.filter, _c = props.disabled, disabled = _c === void 0 ? false : _c, editable = props.editable, _d = props.valueProp, valueProp = _d === void 0 ? 'name' : _d, _e = props.labelProp, labelProp = _e === void 0 ? 'name' : _e, placeholder = props.placeholder, multilevel = props.multilevel, onSelect = props.onSelect, renderItem = props.renderItem, renderFooter = props.renderFooter, customContent = props.customContent;
    var _f = React.useState(false), isOpen = _f[0], setIsOpen = _f[1];
    var _g = React.useState(''), filterText = _g[0], setFilterText = _g[1];
    var _h = React.useState(data), list = _h[0], setList = _h[1];
    var _j = React.useState(width), openingWidth = _j[0], setOpeningWidth = _j[1];
    var filterRef = React.useRef(null);
    var fieldRef = React.useRef(null);
    var _k = useIsScrolling(), isScrolling = _k.isScrolling, handleRef = _k.handleRef;
    React.useEffect(function () {
        var _a;
        if (isOpen) {
            (_a = filterRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            setFilterText('');
        }
    }, [isOpen]);
    React.useEffect(function () {
        if (filterText) {
            setList(data.filter(function (item) {
                return "".concat(item[labelProp]).toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
            }));
        }
        else {
            setList(data);
        }
    }, [filterText, data]);
    var close = function () {
        setIsOpen(false);
    };
    var _l = useLayer({
        isOpen: isOpen,
        auto: true,
        onDisappear: close,
        onOutsideClick: close,
        overflowContainer: true,
        placement: 'bottom-center',
        possiblePlacements: ['top-center', 'bottom-center']
    }), triggerProps = _l.triggerProps, layerProps = _l.layerProps, renderLayer = _l.renderLayer;
    var _select = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsOpen(false);
                    if (!(typeof onSelect === 'function')) return [3 /*break*/, 2];
                    return [4 /*yield*/, onSelect(data, name)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var _open = function (e) {
        var width = e.currentTarget.getBoundingClientRect().width;
        setOpeningWidth(width);
        !disabled && editable && setIsOpen(!isOpen);
    };
    var _renderOpeningContent = function () {
        return list.map(function (item, index) { return (_jsx(Item, { item: item, index: index, value: value, select: _select, width: openingWidth, valueProp: valueProp, labelProp: labelProp, multilevel: multilevel, renderItem: renderItem }, item[valueProp])); });
    };
    var _renderContent = function () {
        var selectedItem = data.find(function (item) {
            return multilevel ? item[valueProp] in value : item[valueProp] === value;
        });
        return (_jsxs(View, __assign({ width: width, 
            // @ts-ignore
            ref: fieldRef, onClick: _open }, triggerProps, { cursor: editable ? 'pointer' : '' }, { children: [label && (_jsx(Text, __assign({ typo: "14/grey190", padding: { vertical: 5 } }, { children: label }))), _jsxs(ContentStyled
                // @ts-ignore
                , __assign({ 
                    // @ts-ignore
                    error: error, isOpen: isOpen, disabled: disabled, padding: { left: customContent ? 0 : 8, right: 8 } }, { children: [_jsx(View, __assign({ overflow: "hidden", grow: 1 }, { children: customContent ? (customContent(selectedItem)) : (_jsx(View, __assign({ flex: true, column: true }, { children: selectedItem ? (_jsx(NowrapText, __assign({ size: 14, color: "grey190" }, { children: selectedItem[labelProp] }))) : (_jsx(NowrapText, __assign({ size: 14, color: "grey90" }, { children: placeholder }))) }))) })), editable && (_jsx(View, __assign({ flex: true, aCenter: true }, { children: _jsx(Icon, { name: isOpen ? 'expand' : 'reduce', size: 16, color: "grey130" }) })))] })), error && (_jsxs(View, __assign({ flex: true, aCenter: true, margin: { top: 5 } }, { children: [_jsx(Icon, { size: 12, name: "warningStroke", color: "dangerStrong" }), _jsx(Text, __assign({ typo: "12/dangerStrong", margin: { left: 5 } }, { children: error }))] })))] })));
    };
    return (_jsxs(_Fragment, { children: [_renderContent(), isOpen &&
                renderLayer(_jsxs(OpeningBlock, __assign({}, layerProps, { shadow: "dreamy", width: openingWidth, zIndex: HIGH_Z_INDEXES.LEVEL_2 }, { children: [filter && (data === null || data === void 0 ? void 0 : data.length) > 6 && (_jsxs(View, __assign({ flex: true, aCenter: true, borderB: "1px solid", borderColor: "theme", padding: { horizontal: 16 } }, { children: [_jsx(Icon, { name: "searchList", color: "dark50", size: 14 }), _jsx(FilterStyled, { ref: filterRef, value: filterText, placeholder: "Search", 
                                    // @ts-ignore
                                    onChange: function () { var _a; return setFilterText((_a = filterRef === null || filterRef === void 0 ? void 0 : filterRef.current) === null || _a === void 0 ? void 0 : _a.value); } })] }))), _jsx(View, __assign({ padding: { vertical: 6, horizontal: 4 } }, { children: _jsxs(ContainerStyled, __assign({ ref: handleRef, scrollbar: "small", isScrolling: isScrolling, onScroll: function (e) { return e.stopPropagation(); } }, { children: [_renderOpeningContent(), renderFooter && (_jsx(View, __assign({ onClick: function () { return setIsOpen(false); } }, { children: renderFooter() })))] })) }))] })))] }));
};
Dropdown.defaultProps = {
    disabled: false,
    placeholder: '',
    filter: true,
    multilevel: false,
    editable: true
};
export default Dropdown;
