/** @format */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '../../../../styles/theme';
var FilterStyled = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 40px;\n  outline: none;\n  border: none;\n  margin-left: 8px;\n  font-size: 14px;\n  color: ", ";\n\n  ::placeholder {\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  height: 40px;\n  outline: none;\n  border: none;\n  margin-left: 8px;\n  font-size: 14px;\n  color: ", ";\n\n  ::placeholder {\n    color: ", ";\n  }\n"])), colors.dark, colors.dark50);
export default FilterStyled;
var templateObject_1;
