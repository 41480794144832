var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHover, useLayer } from 'react-laag';
// components
import Icon from '../../../Icon';
import View from '../../../../styledComponents/View';
import NowrapText from '../../../../styledComponents/NowrapText';
import ItemStyled from '../../styled/ItemStyled';
// constants
import { HIGH_Z_INDEXES } from '../../../../../constants';
// hooks
import useIsScrolling from '../../../../../hooks/useIsScrolling';
var Item = function (props) {
    var item = props.item, width = props.width, value = props.value, index = props.index, valueProp = props.valueProp, labelProp = props.labelProp, multilevel = props.multilevel, select = props.select, renderItem = props.renderItem;
    var _a = useIsScrolling(), isScrolling = _a.isScrolling, handleRef = _a.handleRef;
    var _b = useHover({
        hideOnScroll: false
    }), isOver = _b[0], hoverProps = _b[1];
    var _c = useLayer({
        auto: true,
        isOpen: isOver,
        placement: 'right-start',
        possiblePlacements: ['left-start', 'left-end', 'right-start', 'right-end']
    }), triggerProps = _c.triggerProps, layerProps = _c.layerProps, renderLayer = _c.renderLayer;
    var isActive = multilevel ? item[valueProp] in value : item[valueProp] === value;
    var _renderLayer = function () {
        return (isOver &&
            renderLayer(_jsx(View, __assign({ radius: 4, width: width, shadow: "dreamy" }, layerProps, { bgColor: "white", zIndex: HIGH_Z_INDEXES.LEVEL_3, padding: { right: isScrolling ? 4 : 0 } }, { children: _jsx(View, __assign({ maxH: 250, ref: handleRef, overflow: "auto", scrollbar: "small", padding: { vertical: 6, horizontal: 4 } }, { children: item.data.map(function (subItem, index) { return (_jsx(ItemStyled, __assign({ index: index, active: value[item[valueProp]] === subItem[valueProp], onClick: function () {
                            var _a;
                            return select === null || select === void 0 ? void 0 : select((_a = {}, _a[item[valueProp]] = subItem[valueProp], _a));
                        } }, { children: _jsx(View, __assign({ padding: { horizontal: 8 } }, { children: _jsx(NowrapText, __assign({ size: 14 }, { children: subItem[labelProp] })) })) }), subItem[valueProp])); }) })) }))));
    };
    return (_jsxs(ItemStyled, __assign({ index: index, active: isActive }, (multilevel && item.data ? hoverProps : {}), (multilevel && item.data ? triggerProps : {}), { onClick: function () {
            var _a;
            return !item.data &&
                (select === null || select === void 0 ? void 0 : select(multilevel ? (_a = {}, _a[item[valueProp]] = null, _a) : item[valueProp]));
        } }, { children: [renderItem ? (renderItem(item)) : (_jsx(View, __assign({ padding: { horizontal: 8 }, overflow: "hidden" }, { children: _jsx(NowrapText, __assign({ size: 14 }, { children: item[labelProp] })) }))), item.data && multilevel && (_jsx(View, __assign({ padding: { right: 4 } }, { children: _jsx(View, __assign({ transform: "rotate(90deg)" }, { children: _jsx(Icon, { name: "sortingUp", size: 10, color: "grey130" }) })) }))), item.data && multilevel && _renderLayer()] })));
};
export default Item;
