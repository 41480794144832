import { atom } from 'recoil';
export var addWebsiteIsLoadingAtom = atom({
    key: 'addWebsiteIsLoading',
    default: false,
});
export var getWebsiteIsLoadingAtom = atom({
    key: 'getWebsiteIsLoading',
    default: false,
});
export var getWorkspaceConversationCommonTopicsStatsIsLoadingAtom = atom({
    key: 'getWorkspaceConversationCommonTopicsStatsIsLoading',
    default: false,
});
export var getWorkspaceConversationsSentimentByCommonTopicsStatsIsLoadingAtom = atom({
    key: 'getWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading',
    default: false,
});
export var widgetScriptAtom = atom({
    key: 'widgetScript',
    default: null,
});
export var crawlingDataAtom = atom({
    key: 'crawlingData',
    default: null,
});
export var limitationAtom = atom({
    key: 'limitation',
    default: null,
});
export var voiceLimitationAtom = atom({
    key: 'voiceLimitation',
    default: null,
});
export var workspaceConversationsDashboardStatsAtom = atom({
    key: 'workspaceConversationsDashboard',
    default: null,
});
export var workspaceConversationEscalatedStatsAtom = atom({
    key: 'workspaceConversationEscalatedStats',
    default: [],
});
export var workspaceConversationSessionsStatsAtom = atom({
    key: 'workspaceConversationSessionStats',
    default: [],
});
export var workspaceConversationSessionsBySentimentFilteredAtom = atom({
    key: 'workspaceConversationSessionsBySentimentFiltered',
    default: [],
});
export var workspaceConversationSentimentStatsAtom = atom({
    key: 'workspaceConversationSentimentStats',
    default: [],
});
export var workspaceConversationKnowledgeBaseGapsStatsAtom = atom({
    key: 'workspaceConversationKnowledgeBaseGapsStats',
    default: [],
});
export var workspaceConversationCommonTopicsStatsAtom = atom({
    key: 'workspaceConversationCommonTopicsStats',
    default: null,
});
export var workspaceConversationsSentimentByCommonTopicsStatsAtom = atom({
    key: 'workspaceConversationsSentimentByCommonTopicsStats',
    default: [],
});
// Ticketing System
export var connectIntercomUrlAtom = atom({
    key: 'connectIntercomUrl',
    default: null,
});
export var intercomIdAtom = atom({
    key: 'intercomId',
    default: null,
});
export var intercomIdLoadingAtom = atom({
    key: 'intercomIdLoading',
    default: false,
});
export var analyzedConversationsAtom = atom({
    key: 'analyzedConversations',
    default: null,
});
export var analyzingStateAtom = atom({
    key: 'analyzingInProgressState',
    default: {},
});
export var analyzedConversationsStatsAtom = atom({
    key: 'analyzedConversationsStats',
    default: null,
});
export var analyzedConversationsStatsLoadingAtom = atom({
    key: 'analyzedConversationsStatsLoading',
    default: false,
});
// Analyzed Content
export var analyzedContentAtom = atom({
    key: 'analyzedContent',
    default: null,
});
export var analyzedContentLoadingAtom = atom({
    key: 'analyzedContentLoading',
    default: false,
});
// Common Queries
export var commonQueriesAtom = atom({
    key: 'commonQueries',
    default: null,
});
export var commonQueriesLoadingAtom = atom({
    key: 'commonQueriesLoading',
    default: false,
});
// Conversations
export var conversationsUsersAtom = atom({
    key: 'conversationsUsers',
    default: [],
});
export var conversationsAllUserEmailsAtom = atom({
    key: 'conversationsAllUserEmails',
    default: [],
});
export var conversationsUsersTotalCountAtom = atom({
    key: 'conversationsUsersTotalCount',
    default: 0,
});
export var conversationsUsersIsLoadingAtom = atom({
    key: 'conversationsUsersIsLoading',
    default: false,
});
export var sessionsAtom = atom({
    key: 'sessions',
    default: {},
});
export var sessionsIsLoadingAtom = atom({
    key: 'sessionsIsLoading',
    default: false,
});
export var conversationsAtom = atom({
    key: 'conversations',
    default: {},
});
export var conversationsIsLoadingAtom = atom({
    key: 'conversationsIsLoading',
    default: false,
});
export var workspaceConversationsStatsAtom = atom({
    key: 'workspaceConversationsStats',
    default: null,
});
export var workspaceConversationsStatsIsLoadingAtom = atom({
    key: 'workspaceConversationsStatsIsLoading',
    default: false,
});
export var workspaceVoiceConversationsStatsAtom = atom({
    key: 'workspaceVoiceConversationsStats',
    default: null,
});
export var workspaceVoiceConversationsStatsIsLoadingAtom = atom({
    key: 'workspaceVoiceConversationsStatsIsLoading',
    default: false,
});
// uploads
export var uploadsAtom = atom({
    key: 'uploads',
    default: [],
});
export var uploadsLoadingAtom = atom({
    key: 'uploadsLoading',
    default: false,
});
export var uploadFileLoadingAtom = atom({
    key: 'uploadFileLoading',
    default: false,
});
// recordings
export var recordingsAtom = atom({
    key: 'recordings',
    default: null,
});
export var recordingsLoadingAtom = atom({
    key: 'recordingsLoading',
    default: false,
});
export var recordingsFileLoadingAtom = atom({
    key: 'recordingsFileLoading',
    default: false,
});
// auto generated content
export var generatedAtriclesAtom = atom({
    key: 'generatedAtricles',
    default: [],
});
export var generatedAtriclesLoadingAtom = atom({
    key: 'generatedAtriclesLoading',
    default: false,
});
export var generatedInstructionsAtom = atom({
    key: 'generatedInstructions',
    default: [],
});
export var generatedInstructionsLoadingAtom = atom({
    key: 'generatedInstructionsLoading',
    default: false,
});
// Conversation flows
export var flowsAtom = atom({
    key: 'flows',
    default: [],
});
export var voicesAtom = atom({
    key: 'voices',
    default: [],
});
export var systemTemplatesAtom = atom({
    key: 'systemTemplates',
    default: [],
});
export var twilioCallSetupAtom = atom({
    key: 'twilioCallSetup',
    default: null,
});
export var connectHubSpotUrlAtom = atom({
    key: 'connectHubSpotUrl',
    default: null,
});
export var hubSpotIdAtom = atom({
    key: 'hubSpotId',
    default: null,
});
export var hubSpotIdLoadingAtom = atom({
    key: 'hubSpotIdLoading',
    default: false,
});
