var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bar } from "react-chartjs-2";
import View from "../../styledComponents/View";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartTooltip, Legend, } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);
var SessionAnalyticsChart = function (_a) {
    var data = _a.data;
    var sentimentScores = data.conversationAnalytics.sentimentScore;
    var feedback = data.feedback;
    var qualityMetrics = data.qualityMetrics;
    var lackOfKnowledgebase = data.lackOfKnowledgebase;
    var caseResolutionByBot = data.caseResolutionByBot;
    var overallData = {
        labels: ["Overall"],
        datasets: [
            {
                label: "Sentiment Score",
                data: [sentimentScores.overall.value],
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
                description: [sentimentScores.overall.description],
            },
        ],
    };
    var subtopicData = {
        labels: Object.keys(sentimentScores.bySubtopic),
        datasets: [
            {
                label: "Sentiment Score",
                data: Object.values(sentimentScores.bySubtopic).map(function (subtopic) { return subtopic.value; }),
                backgroundColor: "rgba(153, 102, 255, 0.2)",
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 1,
                description: Object.values(sentimentScores.bySubtopic).map(function (subtopic) { return subtopic.description; }),
            },
        ],
    };
    var feedbackData = {
        labels: ["Rating"],
        datasets: [
            {
                label: "Feedback Score",
                data: [feedback.rating.value],
                backgroundColor: "rgba(255, 159, 64, 0.2)",
                borderColor: "rgba(255, 159, 64, 1)",
                borderWidth: 1,
                description: [feedback.rating.description],
            },
        ],
        plugins: {
            tooltip: {
                enabled: true,
                boundary: 'chartArea', // Constrain tooltips to the chart area
            }
        }
    };
    var detailedFeedbackData = {
        labels: Object.keys(feedback.detailedScores),
        datasets: [
            {
                label: "Detailed Feedback Score",
                data: Object.values(feedback.detailedScores).map(function (score) { return score.value; }),
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
                description: Object.values(feedback.detailedScores).map(function (score) { return score.description; }),
            },
        ],
        plugins: {
            tooltip: {
                enabled: true,
                boundary: 'chartArea', // Constrain tooltips to the chart area
            }
        }
    };
    var qualityMetricsData = {
        labels: Object.keys(qualityMetrics),
        datasets: [
            {
                label: "Quality Metrics Score",
                data: Object.values(qualityMetrics).map(function (metric) { return metric.value; }),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
                description: Object.values(qualityMetrics).map(function (metric) { return metric.description; }),
            },
        ],
        plugins: {
            tooltip: {
                enabled: true,
                boundary: 'chartArea', // Constrain tooltips to the chart area
            }
        }
    };
    var options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                intersect: false,
                boundary: 'chartArea',
                callbacks: {
                    label: function (context) {
                        var description = context.dataset.description[context.dataIndex];
                        var chunkedDescription = description.split(' ').reduce(function (acc, word, index) {
                            var chunkIndex = Math.floor(index / 5);
                            if (!acc[chunkIndex]) {
                                acc[chunkIndex] = []; // start a new chunk
                            }
                            acc[chunkIndex].push(word);
                            return acc;
                        }, []).map(function (chunk) { return chunk.join(' '); });
                        return __spreadArray(["".concat(context.dataset.label, ":").concat(context.raw)], chunkedDescription, true);
                    },
                },
            },
        },
    };
    return (_jsx(View, __assign({ flex: true, column: true, between: true, top: "0", right: "0", shadow: "dreamy", bgColor: "background" }, { children: _jsx(View, __assign({ flex: true, column: true }, { children: _jsx(View, __assign({ width: "100%" }, { children: _jsxs(View, __assign({ style: {
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 205px)",
                        flex: 1,
                    } }, { children: [_jsx("h2", { children: "Case Resolution by Bot" }), _jsxs("div", { children: [_jsxs("p", { children: ["Resolved by Bot: ", caseResolutionByBot.value ? "Yes" : "No"] }), _jsxs("p", { children: ["Description: ", caseResolutionByBot.description] })] }), _jsx("h2", { children: "Lack of Knowledgebase" }), _jsxs("div", { children: [_jsxs("p", { children: ["Knowledge Base Content Available:", " ", lackOfKnowledgebase.present ? "No" : "Yes"] }), _jsxs("p", { children: ["Description: ", lackOfKnowledgebase.description] }), lackOfKnowledgebase.missingTopics.length > 0 && (_jsxs("div", { children: [_jsx("h3", { children: "Missing Topics:" }), _jsx("ul", { children: lackOfKnowledgebase.missingTopics.map(function (topic, index) { return (_jsx("li", { children: topic }, index)); }) })] }))] }), _jsx("h2", { children: "Overall Sentiment Score" }), _jsx(Bar, { data: overallData, options: options }), _jsx("h2", { children: "Sentiment Scores by Subtopic" }), _jsx(Bar, { data: subtopicData, options: options }), _jsx("h2", { children: "Overall Feedback Rating" }), _jsx(Bar, { data: feedbackData, options: options }), _jsx("h2", { children: "Detailed Feedback Scores" }), _jsx(Bar, { data: detailedFeedbackData, options: options }), _jsx("h2", { children: "Quality Metrics Scores" }), _jsx(Bar, { data: qualityMetricsData, options: options })] })) })) })) })));
};
export default SessionAnalyticsChart;
