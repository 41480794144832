var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import View from '../../../styledComponents/View';
import { colors } from '../../../../styles/theme';
var ContentStyled = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  display: flex;\n  transition: 0.1s;\n  border: 1px solid;\n  border-radius: 4px;\n  align-items: center;\n  justify-content: space-between;\n\n  ", "\n"], ["\n  height: 40px;\n  display: flex;\n  transition: 0.1s;\n  border: 1px solid;\n  border-radius: 4px;\n  align-items: center;\n  justify-content: space-between;\n\n  ", "\n"])), function (_a) {
    var disabled = _a.disabled, isOpen = _a.isOpen, error = _a.error;
    return "\n    background-color: ".concat(disabled ? colors['grey30'] : colors.white, ";\n    border-color: ").concat(disabled
        ? colors.none
        : error
            ? colors.dangerStrong
            : isOpen
                ? colors.theme
                : colors.borderGray, ";\n\n    &:hover {\n      ").concat(!isOpen &&
        !error &&
        !disabled &&
        "\n          border-color: ".concat(colors.borderDark, ";\n        "), "\n    }\n  ");
});
export default ContentStyled;
var templateObject_1;
