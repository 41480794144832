import { FilterEnums } from '../types/enums';
export function openSidebar() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
}
export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}
export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        var slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        }
        else {
            openSidebar();
        }
    }
}
export var hasChild = function (parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
};
export var createQueryArrayParam = function (key, values) {
    var encodedValues = values.map(function (value) { return encodeURIComponent(value); });
    var queryParams = encodedValues.map(function (value) { return "".concat(key, "=").concat(value); });
    var queryString = queryParams.join('&');
    return queryString;
};
export var generateQueryParams = function (params) {
    if (!params || Object.keys(params).length === 0) {
        return '';
    }
    var queryParams = [];
    for (var key in params) {
        if (Array.isArray(params[key])) {
            queryParams.push(createQueryArrayParam(key, params[key]));
        }
        else if (Object.prototype.hasOwnProperty.call(params, key)) {
            if (params[key] != null && params[key] !== undefined) {
                var encodedKey = encodeURIComponent(key);
                var encodedValue = encodeURIComponent(params[key]);
                queryParams.push("".concat(encodedKey, "=").concat(encodedValue));
            }
        }
    }
    return queryParams.join('&');
};
export var dateFilterValuesGenerator = function (target) {
    var today = new Date();
    var currentDay = today.getDay();
    var startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    var yesterdayStart = new Date(today);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    yesterdayStart.setHours(0, 0, 0, 0);
    var yesterdayEnd = new Date(yesterdayStart);
    yesterdayEnd.setHours(23, 59, 59, 999);
    var thisWeek = new Date(today);
    var diffThisWeek = thisWeek.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
    var startOfWeek = new Date(thisWeek.setDate(diffThisWeek));
    startOfWeek.setHours(0, 0, 0, 0);
    var lastWeek = new Date(today);
    var diffLastWeek = lastWeek.getDate() - currentDay - 6;
    var startOfLastWeek = new Date(lastWeek.setDate(diffLastWeek));
    startOfLastWeek.setHours(0, 0, 0, 0);
    var endOfLastWeek = new Date(startOfLastWeek);
    endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
    endOfLastWeek.setHours(23, 59, 59, 999);
    var startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    var lastMonthEnd = new Date(startOfMonth);
    lastMonthEnd.setDate(startOfMonth.getDate() - 1);
    var lastMonthStart = new Date(lastMonthEnd);
    lastMonthStart.setDate(1);
    var last60Days = new Date(today);
    last60Days.setDate(last60Days.getDate() - 60);
    last60Days.setHours(0, 0, 0, 0);
    var last90Days = new Date(today);
    last90Days.setDate(last90Days.getDate() - 90);
    last90Days.setHours(0, 0, 0, 0);
    switch (target) {
        case FilterEnums.today: return {
            startDate: startOfToday.toISOString(),
            endDate: today.toISOString(),
        };
        case FilterEnums.yesterday: return {
            startDate: yesterdayStart.toISOString(),
            endDate: yesterdayEnd.toISOString(),
        };
        case FilterEnums.thisWeek: return {
            startDate: startOfWeek.toISOString(),
            endDate: today.toISOString(),
        };
        case FilterEnums.lastWeek: return {
            startDate: startOfLastWeek.toISOString(),
            endDate: endOfLastWeek.toISOString(),
        };
        case FilterEnums.thisMoth: return {
            startDate: startOfMonth.toISOString(),
            endDate: today.toISOString(),
        };
        case FilterEnums.lastMonth: return {
            startDate: lastMonthStart.toISOString(),
            endDate: lastMonthEnd.toISOString(),
        };
        case FilterEnums.last60Days: return {
            startDate: last60Days.toISOString(),
            endDate: today.toISOString(),
        };
        case FilterEnums.last90Days: return {
            startDate: last90Days.toISOString(),
            endDate: today.toISOString(),
        };
        default: return {};
    }
};
