var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/joy';
import KnowledgeBase from './KnowledgeBase';
import DataCollection from './DataCollection';
export default function Plugins(props) {
    var flow = props.flow, invalidFields = props.invalidFields, isKnowledgeBaseEnabled = props.isKnowledgeBaseEnabled, onIsKnowledgeBaseEnabled = props.onIsKnowledgeBaseEnabled, onUseKnowledgeBaseChange = props.onUseKnowledgeBaseChange, onStepByStepTroubleshootingPluginChange = props.onStepByStepTroubleshootingPluginChange, onUseUploadedFilesChange = props.onUseUploadedFilesChange, onUseWebsiteContentChange = props.onUseWebsiteContentChange, onUseAutoGeneratedArticlesChange = props.onUseAutoGeneratedArticlesChange, onUseAutoGeneratedInstructionsChange = props.onUseAutoGeneratedInstructionsChange, onUseDraftAutoGeneratedContentChange = props.onUseDraftAutoGeneratedContentChange, onNewPlugin = props.onNewPlugin, onPluginNameChange = props.onPluginNameChange, onPluginIsEnabledChange = props.onPluginIsEnabledChange, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginNewVariable = props.onPluginNewVariable, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginActionTypeChange = props.onPluginActionTypeChange, onPluginExecuteAfterChange = props.onPluginExecuteAfterChange, onPluginInjectResultChange = props.onPluginInjectResultChange, onPluginActionArgsPropertyChange = props.onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader = props.onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader = props.onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader = props.onPluginActionArgsDeleteHeader, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue;
    return (_jsxs(Stack, __assign({ spacing: 2, sx: { flexGrow: 1 } }, { children: [_jsx(KnowledgeBase, { flow: flow, isKnowledgeBaseEnabled: isKnowledgeBaseEnabled, onIsKnowledgeBaseEnabled: onIsKnowledgeBaseEnabled, onUseKnowledgeBaseChange: onUseKnowledgeBaseChange, onStepByStepTroubleshootingPluginChange: onStepByStepTroubleshootingPluginChange, onUseAutoGeneratedArticlesChange: onUseAutoGeneratedArticlesChange, onUseAutoGeneratedInstructionsChange: onUseAutoGeneratedInstructionsChange, onUseDraftAutoGeneratedContentChange: onUseDraftAutoGeneratedContentChange, onUseUploadedFilesChange: onUseUploadedFilesChange, onUseWebsiteContentChange: onUseWebsiteContentChange }), _jsx(DataCollection, { plugins: flow.plugins, invalidFields: invalidFields, onNewPlugin: onNewPlugin, onPluginNameChange: onPluginNameChange, onPluginIsEnabledChange: onPluginIsEnabledChange, onPluginVariablePropertyChange: onPluginVariablePropertyChange, onPluginNewVariable: onPluginNewVariable, onPluginDeleteVariable: onPluginDeleteVariable, onPluginActionTypeChange: onPluginActionTypeChange, onPluginExecuteAfterChange: onPluginExecuteAfterChange, onPluginInjectResultChange: onPluginInjectResultChange, onPluginActionArgsPropertyChange: onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader: onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader: onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader: onPluginActionArgsDeleteHeader, onPluginVariableAddEnumValue: onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue: onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue: onPluginVariableDeleteEnumValue })] })));
}
